export const BanksSimulatorFGTS = [
  {
    id: 6,
    febrabanId: '329',
    name: 'ICRED',
  },
  {
    id: 19,
    febrabanId: '329-5',
    name: 'GRANDINO BANK',
  },
  {
    id: 3,
    febrabanId: '149',
    name: 'FACTA',
  },
  {
    id: 21,
    febrabanId: '531',
    name: 'PRESENÇA BANK',
  },
  {
    id: 20,
    febrabanId: '329-6',
    name: 'VCTEX',
  },
];
