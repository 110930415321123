import { Alert, Badge, Button, Col, Row } from 'reactstrap';
import { moneyMask } from 'utils/mask';

const TableResultSimulationComponent = ({
  table,
  handleSelectSimulation = () => {},
}) => {
  return (
    <>
      <Row>
        <Col md="12">
          <p className="m-0">Tabela banco:</p>
          <p className="m-0 font-weight-bold mb-2 font-20">
            {table.name}{' '}
            {table?.useSafe > 0 && <Badge color="success">COM SEGURO</Badge>}
          </p>
          <p className="m-0">
            Parcelas:{' '}
            <strong className="font-weight-bold">
              {table?.qtdInstallment && table?.qtdInstallment > 0
                ? table.qtdInstallment
                : table.installments.length}
            </strong>
          </p>
          <p className="m-0">
            Vlr. Total:{' '}
            <strong className="font-weight-bold">
              R$ {table?.valueFinally?.toFixed(2)}
            </strong>
          </p>
          <p className="m-0 font-24">
            Vlr. Liberado:{' '}
            <strong className="font-weight-bold text-success">
              R$ {table?.valueLiberty?.toFixed(2)}
            </strong>
          </p>
          <div className="p-3 mb-2 rounded bg-secondary">
            <p className="m-0 font-16">
              % Comissinamento:{' '}
              <strong className="font-weight-bold">
                {table?.comission ? table?.comission : 'Não informado'}
              </strong>
            </p>
            <p className="m-0 font-16">
              Comissionamento:{' '}
              <strong className="font-weight-bold text-success">
                {table?.valueComission
                  ? `R$ ${moneyMask(table.valueComission.toFixed(2))}`
                  : 'Não informado'}
              </strong>
            </p>
          </div>
        </Col>

        <Col md="12">
          <Button
            color="success"
            className="w-100 mt-2 mb-2"
            onClick={handleSelectSimulation}
          >
            <i className="fas fa-check"></i> Selecionar
          </Button>
        </Col>
      </Row>

      {/* {table.tableComission && (
        <Row className="mt-3">
          <Alert color="secondary">
            <Badge color="success" className="font-12">
              Repasse
            </Badge>
            <h3 className="mt-2">{table.tableComission}</h3>
            <p className="m-0">
              Base Calculo:{' '}
              <strong className="font-weight-bold">
                R$ {table?.valueLiberty?.toFixed(2)}
              </strong>
            </p>
            <p className="m-0">
              % Repasse:{' '}
              <strong className="font-weight-bold">{table.comission}</strong>
            </p>
            <p className="m-0 font-20">
              Vlr. Repasse:{' '}
              <strong className="font-weight-bold text-success">
                R$ {moneyMask(table.valueComission.toFixed(2))}
              </strong>
            </p>
          </Alert>
        </Row>
      )} */}
    </>
  );
};

export default TableResultSimulationComponent;
