import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableData,
    Table
} from 'components/Table';

import Header from 'components/Headers/Header';
import { MobileTitle } from 'views/Dashboard/styles';
import { ContentWrapper } from 'views/Dashboard/styles';
import { CaretRight } from 'phosphor-react';

import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
} from 'reactstrap';

import Swal from 'sweetalert2';

import { listComissionImportedService } from 'services/Import/ImportProposalService';

const ComissionImportedView = () => {
    let { fileImportedId } = useParams();
    const [result, setResult] = useState([]);
    const [tableDetails, setTableDetails] = useState(null);
    const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
    const [filter, setFilter] = useState({
        previusLimit: 0,
        limit: 15
    });

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('pt-BR');
    };

    const handleList = async (isMore) => {
        Swal.fire({
            title: 'Carregando, Por favor aguarde...',
            message: 'Por favor aguarde',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        let filterSearch = {
            ...filter
        };

        if (!isMore) {
            filterSearch = {
                ...filter,
                previusLimit: 0
            };
        }

        try {
            const result = await listComissionImportedService(fileImportedId, filterSearch);
            Swal.close();

            setFilter(prevFilter => ({
                ...prevFilter,
                previusLimit: isMore ? prevFilter.previusLimit + prevFilter.limit : prevFilter.limit
            }));

            const newResultSet = result.data.resultSet;

            if (isMore) {
                setResult(prevResult => {
                    const combinedResult = [...prevResult, ...newResultSet];
                    const filteredResult = combinedResult.filter((item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id)
                    );
                    return filteredResult;
                });
            } else {
                setResult(newResultSet);
            }

            setShowBtnMoreRegisters(newResultSet.length >= filter.limit);

        } catch (err) {
            const code = !err.response ? '00000' : err.response.data.code;
            const message = !err.response
                ? 'Ocorreu um erro inesperado'
                : err.response.data.message;
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `[${code}] ${message}`
            });
        }
    };

    const handleTableDetails = (index) => {
        if (tableDetails === index) return setTableDetails(null);

        setTableDetails(index);
    };

    useEffect(() => {
        handleList();
    }, []);

    return (
        <>
            <Header titlePage="Comissionamento - Registros importados" />
            <Container className="mt--9" fluid>
                {result.length > 0 ? (
                    <Card className="mt-3">
                        <CardHeader className='border-bottom-0 pb-0'>Registros Importados</CardHeader>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>DATA CADASTRO</TableHead>
                                    <TableHead>CLIENTE</TableHead>
                                    <TableHead className="d-none d-sm-table-cell">DATA PAGAMENTO</TableHead>
                                    <TableHead className="d-none d-sm-table-cell">DATA PROPOSTA</TableHead>
                                    <TableHead className="d-none d-md-table-cell">NUMERO PROPOSTA</TableHead>
                                    <TableHead className="d-none d-lg-table-cell">NOME TABELA</TableHead>
                                    <TableHead className="d-none d-xl-table-cell">VALOR COMISSIONAMENTO</TableHead>
                                    <TableHead className="d-none d-xl-table-cell">STATUS</TableHead>
                                    <TableHead className="d-xl-none" />
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {result.map((register, key) => (
                                    <>
                                        <TableRow key={key}>
                                            <TableData>{formatDate(register.createdAt)}</TableData>
                                            <TableData>
                                                <p className="m-0">
                                                    CPF: <strong>{register.cpf || "NÃO INFORMADO"}</strong>
                                                </p>
                                            </TableData>
                                            <TableData className="d-none d-sm-table-cell">{formatDate(register.datePayment)}</TableData>
                                            <TableData className="d-none d-sm-table-cell">{formatDate(register.dateProposal)}</TableData>
                                            <TableData className="d-none d-md-table-cell">{register.numberProposal}</TableData>
                                            <TableData className="d-none d-lg-table-cell">
                                                <p className="m-0">
                                                    <strong>{register.tableName}</strong>
                                                </p>
                                            </TableData>
                                            <TableData className="d-none d-xl-table-cell">
                                                <p className="m-0">
                                                    <strong>R$ {register.valueBasisCommission}</strong>
                                                </p>
                                            </TableData>
                                            <TableData className="d-none d-xl-table-cell">
                                                {register.status === "AGUARDANDO" ? (
                                                    <Badge color="warning">AGUARDANDO</Badge>
                                                ) : register.status === "CANCELADO" ? (
                                                    <Badge color="danger">CANCELADO</Badge>
                                                ) : (
                                                    <Badge color="success">PROCESSADO</Badge>
                                                )}
                                            </TableData>
                                            <TableData className="d-xl-none">
                                                <CaretRight
                                                    active={tableDetails === key}
                                                    onClick={() => handleTableDetails(key)}
                                                />
                                            </TableData>
                                        </TableRow>
                                        {tableDetails === key && (
                                            <TableRow className="d-xl-none">
                                                <TableData colSpan={10}>
                                                    <ContentWrapper className="d-sm-none">
                                                        <MobileTitle>DATA PAGAMENTO</MobileTitle>
                                                        <p className="m-0">
                                                            {formatDate(register.datePayment)}
                                                        </p>
                                                    </ContentWrapper>
                                                    <ContentWrapper className="d-sm-none">
                                                        <MobileTitle>DATA PROPOSTA</MobileTitle>
                                                        <p className="m-0">
                                                            {formatDate(register.dateProposal)}
                                                        </p>
                                                    </ContentWrapper>
                                                    <ContentWrapper className="d-md-none">
                                                        <MobileTitle>NUMERO PROPOSTA</MobileTitle>
                                                        <p className="m-0">
                                                            {register.numberProposal}
                                                        </p>
                                                    </ContentWrapper>
                                                    <ContentWrapper className="d-lg-none">
                                                        <MobileTitle>NOME TABELA</MobileTitle>
                                                        <p className="m-0">
                                                            <strong>{register.tableName}</strong>
                                                        </p>
                                                    </ContentWrapper>
                                                    <ContentWrapper>
                                                        <MobileTitle>VALOR COMISSIONAMENTO</MobileTitle>
                                                        <p className="m-0">
                                                            <strong>R$ {register.valueBasisCommission}</strong>
                                                        </p>
                                                    </ContentWrapper>
                                                    <ContentWrapper className="d-xl-none">
                                                        <MobileTitle>Status</MobileTitle>
                                                        {register.status === "AGUARDANDO" ? (
                                                            <Badge color="warning">AGUARDANDO</Badge>
                                                        ) : register.status === "CANCELADO" ? (
                                                            <Badge color="danger">CANCELADO</Badge>
                                                        ) : (
                                                            <Badge color="success">PROCESSADO</Badge>
                                                        )}
                                                    </ContentWrapper>
                                                </TableData>
                                            </TableRow>
                                        )}
                                    </>
                                ))}
                            </TableBody>
                            {showBtnMoreRegisters && (
                                <tr>
                                    <td colSpan={12} className='pt-3 px-3'>
                                        <Button
                                            className="w-100"
                                            onClick={() => {
                                                setFilter({ ...filter });
                                                handleList(true);
                                            }}
                                        >
                                            Carregar mais...
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </Table>
                    </Card>
                ) : (
                    <Card className="mt-3">
                        <CardHeader className=''>Registros Importados</CardHeader>
                        <CardBody className="text-start">
                            <p>Sem registros importados...</p>
                        </CardBody>
                    </Card>
                )}
            </Container>
        </>
    );
};

export default ComissionImportedView;