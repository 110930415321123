import Header from 'components/Headers/Header';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { reSendLinkFormalizationMercantil } from 'services/Digitation/DigitationService';
import { showLinkFormalize } from 'services/Digitation/DigitationService';
import { UpdateStatusProposal } from 'services/Digitation/ProposalService';
import {
  showStatusProposal,
  showProposalDetails,
} from 'services/Digitation/ProposalService';
import Swal from 'sweetalert2';

import { moneyMask } from 'utils/mask';
import CardClientComponent from './components/CardClientComponent';
import CardAddressComponent from './components/CardAddressComponent';
import BankDetailsComponent from './components/BankDetailsComponent';


const ProposalDetails = () => {
  let { id } = useParams();
  const [proposal, setProposal] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const userData = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));

  const handleShow = async (proposalId) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    showProposalDetails(proposalId)
      .then((result) => {
        Swal.close();
        setProposal(result.data.resultSet);
        setShowLoading(false);
      })
      .catch((err) => {
        setShowLoading(false);
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const closePage = () => {
    window.close();
  };

  const handleShowStatusProposal = (febrabanId, proposalId) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    showStatusProposal(febrabanId, proposalId)
      .then((res) => {
        const result = res.data.resultSet;
        Swal.fire({
          icon: 'info',
          html: `
            <p class="m-0">Situação: <strong>${result.situation}</strong></p>
            <p class="m-0">Atividade: <strong>${result.activity}</strong></p>
          `,
        });
      })
      .catch((err) => {
        setShowLoading(false);
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const reSendLinkMercantil = async (simulationId) => {
    Swal.fire({
      title: 'ReEnviando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    reSendLinkFormalizationMercantil(simulationId)
      .then((res) => {
        const link = res.data.link;

        Swal.fire({
          icon: 'info',
          title: 'Sucesso',
          html: `<h3>Link ReEnviado com sucesso!</h3>
          <input class="form-control form-control" type="text" value="${link}" />`,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const getLinkFormalize = async (numberProposal, febrabanId) => {
    Swal.fire({
      title: 'Buscando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    showLinkFormalize(numberProposal, febrabanId)
      .then((res) => {
        const link = res.data.resultSet;
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: `<input class="form-control form-control" type="text" value="${link}" />`,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdateStatus = async (proposalId, statusId) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja alterar o status da Proposta?',
      showDenyButton: true,
      denyButtonText: 'Sair',
      confirmButtonText: 'Ok, Alterar status',
    }).then(async (confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Realizando alteração',
        text: 'Por favor, aguarde...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await UpdateStatusProposal(proposalId, statusId)
        .then(() => {
          setProposal({
            ...proposal,
            statusStageProposalId: statusId,
          });
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Status alterado com sucess',
          });
        })
        .catch((err) => {
          setShowLoading(false);
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  useEffect(() => {
    handleShow(id);
  }, []);

  if (showLoading) {
    return (
      <>
        <Header />
        <Container className="mt--9" fluid>
          <Card>
            <CardBody className="text-center text-info">
              <i className="fas fa-spinner fa-4x fa-spin" />
              <h1>Carregando</h1>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }

  if (!proposal) {
    return (
      <>
        <Header />
        <Container className="mt--9" fluid>
          <Card>
            <CardBody className="text-center text-danger">
              <i className="fas fa-exclamation-triangle fa-4x" />
              <h1>Não foram identificados registros para esta consulta</h1>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <h2 className="m-0">
              <i className="fas fa-receipt" /> Detalhes da proposta
              <div className="float-right">
                <Button color="danger" onClick={closePage}>
                  <i className="fas fa-times" /> Sair
                </Button>
              </div>
            </h2>
          </CardHeader>
          <CardBody>
            <div className="bg-secondary border rounded">
              <Row className="d-flex align-items-stretch">
                <Col md="3">
                  <div className="bg-success p-3 rounded">
                    <h3 className="m-0 text-white">Número do contrato</h3>
                    <h1 className="m-0 text-white">
                      {proposal.numberProposal}
                    </h1>
                  </div>
                </Col>
                <Col md="9" className="d-flex align-content-center flex-wrap">
                  <Row className="w-100 p-3">
                    <Col md="3">
                      <p className="m-0">Banco</p>
                      <h2 className="m-0">{proposal.nameFebraban}</h2>
                    </Col>
                    <Col md="3">
                      <p className="m-0">Status</p>
                      <h2 className="m-0">
                        {proposal.status ?? 'Aguardando'}
                        {(proposal.febrabanId === '074' ||
                          proposal.febrabanId === '389' ||
                          proposal.febrabanId === '274') && (
                            <>
                              <br />
                              <Button
                                color="success"
                                size="sm"
                                onClick={() => {
                                  handleShowStatusProposal(
                                    proposal.febrabanId,
                                    proposal.id
                                  );
                                }}
                              >
                                Status no Banco
                              </Button>
                            </>
                          )}
                      </h2>
                    </Col>
                    <Col md="3">
                      <p className="m-0">Valor Liberado</p>
                      <h2 className="m-0">
                        R$ {moneyMask(proposal.valueLiberty)}
                      </h2>
                    </Col>
                    <Col md="3">
                      <p className="m-0">Valor do Contrato</p>
                      <h2 className="m-0">
                        R$ {moneyMask(proposal.valueFinally)}
                      </h2>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {proposal.timeLineStatus.length > 0 && (
              <div className="bg-secondary border mt-3 rounded p-4">
                <h2>Linha do tempo - Atualização de status</h2>
                <ul className="timeline">
                  {proposal.timeLineStatus.map((register, key) => (
                    <li key={key}>
                      <div className="timeline-time">
                        <span className="date">{register.day}</span>
                        <span className="time">{register.hour}</span>
                      </div>
                      <div className="timeline-icon">
                        <a href="#">&nbsp;</a>
                      </div>
                      <div className="timeline-body">
                        <p className="m-0">
                          Situação: <strong>{register.situation}</strong>
                        </p>
                        <p className="m-0">
                          Atividade: <strong>{register.activity}</strong>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <Row className="mt-4">
              <>
                {proposal.febrabanId == '626' ||
                  proposal.febrabanId == '149' ||
                  proposal.febrabanId == '623' ||
                  proposal.febrabanId == '329-1' ||
                  proposal.febrabanId == '329-3' ||
                  proposal.febrabanId == '329-4' ||
                  proposal.febrabanId == '329-5' ||
                  proposal.febrabanId == '329-6' ||
                  proposal.febrabanId == '074' ||
                  proposal.febrabanId == '274' ||
                  proposal.febrabanId == '457' ||
                  proposal.febrabanId == '324' ? (
                  <>
                    <Col md="3">
                      <FormGroup>
                        <Label>Formalização</Label> <br />
                        <Button
                          color="warning"
                          size="md"
                          onClick={() => {
                            getLinkFormalize(
                              proposal.numberProposal,
                              proposal.febrabanId
                            );
                          }}
                        >
                          <i className="fas fa-link" /> Link de Formalização
                        </Button>
                      </FormGroup>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </>
              {proposal.urlFormalize && (
                <>
                  <br />
                  <a
                    href={proposal.urlFormalize}
                    className="btn btn-link btn-md"
                    target="blank"
                  >
                    <i className="fas fa-link" /> Link de formalização
                  </a>
                </>
              )}
              <>
                {proposal.febrabanId == '389' && (
                  <Col md="3">
                    <FormGroup>
                      <Label>Formalização</Label> <br />
                      <Button
                        onClick={() =>
                          reSendLinkMercantil(proposal.simulationId)
                        }
                      >
                        <i className="fas fa-link" /> Link de Formalização
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </>
            </Row>
          </CardBody>
        </Card>
        {userData?.isChangeStatusProposal && (
          <Card className="shadow mt-3">
            <CardHeader className="border-0">
              <h2 className="m-0">
                <i className="fas fa-user-check" /> Atualizar Proposta
              </h2>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <Label>Status da Proposta</Label>
                  <select
                    value={proposal.statusStageProposalId}
                    onChange={(ev) => {
                      handleUpdateStatus(proposal.id, ev.target.value);
                    }}
                    className="form-control"
                  >
                    <option value="">SELECIONE...</option>
                    <option value="1">AGUARDANDO</option>
                    <option value="2">PAGO</option>
                    <option value="3">ANALISE</option>
                    <option value="4">PENDENCIA</option>
                    <option value="5">REPROVADO</option>
                    <option value="6">CANCELADO</option>
                  </select>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}

        <CardClientComponent
          proposal={proposal}
        />
        <CardAddressComponent
          proposal={proposal}
        />
        <BankDetailsComponent
          proposal={proposal}
        />
        
        <Card className="mt-3">
          <CardFooter>
            <Button color="danger" onClick={closePage}>
              <i className="fas fa-times" /> Sair
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default ProposalDetails;
