import api from 'services/api';

export const insertStoreService = async (data) => {
  return await api.post(`v3/store/create`, data);
};

export const getAllStores = async (filter) => {
  return await api.get(`v3/store`, {
    params: filter,
  });
};

export const toggleStoreStatus = async (storeId) => {
  return await api.put(`v3/store/active-inactive/${storeId}`);
};