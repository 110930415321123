import api from 'services/api';

export const ImportProposalService = (formData, onUploadProgress) => {
  return api.post('/v3/import/proposal-bank', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};


export const listComissionImportedService = (fileImportedId, filterSearch) => {
  return api.get(`/v2/seller/comission/imported/${fileImportedId}`, filterSearch);
};

export const listFilesImportedService = (typeFile) => {
  return api.get(`/v3/import/proposal-bank?typeFile=${typeFile}`)
};