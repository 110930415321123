import { useState } from 'react';

import Header from 'components/Headers/Header';
import {
  TableHeader,
  TableHead,
  TableBody,
  TableData,
  TableRow,
  Table,
} from 'components/Table';
import {
  Alert,
  Badge,
  Card,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import { phoneMask, maskCPF, moneyMask } from 'utils/mask';

import {
  ContentWrapper,
  MobileTitle,
  CaretRight,
} from 'views/Dashboard/styles';
import FilterProposalFgts from './components/FilterProposalFgts';
import ModalTagComponent from 'components/ModalTag/ModalTagComponent';
import Swal from 'sweetalert2';
import CardProposalFgtsComponent from './components/CardProposalFgtsComponent';

import { indexProposalReport } from 'services/Digitation/ProposalService';
import PaginationSelector from 'views/Dashboard/component/PaginationSelector';

const ProposalReportFgts = () => {
  const [toggleShowList, setToggleShowList] = useState(0);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [filter, setFilter] = useState({});
  const [data, setData] = useState({
    data: [],
  });
  const [tableDetails, setTableDetails] = useState(null);

  const [tagDataModal, setTagDataModal] = useState({
    isOpen: false,
    clientId: null,
  });

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const handleSubmit = (pageSelected = 0, newFilter = null) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (!newFilter) {
      newFilter = filter;
    }

    indexProposalReport({
      ...newFilter,
      page: pageSelected ?? 1,
    })
      .then((res) => {
        Swal.close();
        setData(res.data.resultSet);
        if (res?.data?.resultSet?.data?.length > 0) {
          setPages(res.data.resultSet.pagination.result.pages);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  return (
    <>
      <Header titlePage="FGTS - PROPOSTAS DIGITADAS" />

      <Container className="mt--9" fluid>
        <FilterProposalFgts
          setData={setData}
          setToggleShowList={setToggleShowList}
          toggleShowList={toggleShowList}
          filter={filter}
          setFilter={setFilter}
          handleSubmit={handleSubmit}
        />

        {data.data.length > 0 ? (
          <>
            {toggleShowList === 1 && (
              <Card className="mt-3 pt-3">
                {pages.length > 0 ? (
                  <PaginationSelector
                    pages={pages}
                    list={handleSubmit}
                    setPage={setPage}
                    page={page}
                  />
                ) : (
                  <></>
                )}

                <CardHeader>Registros</CardHeader>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Data Cadastro</TableHead>
                      <TableHead>Cliente</TableHead>
                      <TableHead className="d-none d-xl-table-cell">
                        Contrato
                      </TableHead>
                      <TableHead className="d-none d-xl-table-cell">
                        Status
                      </TableHead>
                      <TableHead className="d-none d-xl-table-cell">
                        Opções
                      </TableHead>
                      <TableHead className="d-xl-none" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data.data.map((register, key) => (
                      <>
                        <TableRow style={{ borderBottom: 0 }}>
                          {register?.tags?.length > 0 && (
                            <TableData colSpan={5}>
                              <div className="bg-secondary rounded p-2">
                                {register.tags.map((tag, tagKey) => (
                                  <Badge
                                    key={tagKey}
                                    className={`${tag.color} m-1 font-12 shadow`}
                                  >
                                    {tag.title}
                                  </Badge>
                                ))}
                              </div>
                            </TableData>
                          )}
                        </TableRow>
                        <TableRow style={{ borderTop: 0 }} key={key}>
                          <TableData>{register.createdAt}</TableData>
                          <TableData>
                            <p className="m-0">
                              Nome:{' '}
                              <strong className="font-weight-bold">
                                {register.name}
                              </strong>
                            </p>
                            <p className="m-0">
                              CPF:{' '}
                              <strong className="font-weight-bold">
                                {maskCPF(register.cpf)}
                              </strong>
                            </p>
                            <p className="m-0">
                              Data Nascimento:{' '}
                              <strong className="font-weight-bold">
                                {register.birthDate}
                              </strong>
                            </p>
                            <p className="m-0">
                              <p className="text-primary font-weight-bold">
                                {phoneMask(register.whatsappNumber)} {` `}
                                <a
                                  className="btn btn-success btn-sm"
                                  href={`https://wa.me/55${register.whatsappNumber}`}
                                  target="_blank"
                                >
                                  <i className="fab fa-whatsapp" />
                                </a>
                              </p>
                            </p>
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <p className="m-0">
                              Banco:{' '}
                              <strong className="font-weight-bold">
                                {register.febrabanId} - {register.nameBank}
                              </strong>
                            </p>
                            <p className="m-0">
                              Proposta:{' '}
                              <Badge color="warning">
                                {register.numberProposal}
                              </Badge>
                            </p>
                            <p className="m-0">
                              Vlr. Total FGTS:{' '}
                              <strong className="font-weight-bold">
                                R$ {moneyMask(register.valueFinally)}
                              </strong>
                            </p>
                            <p className="m-0">
                              Vlr. Liberado:{' '}
                              <strong className="font-weight-bold">
                                R$ {moneyMask(register.valueLiberty)}
                              </strong>
                            </p>
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            {register.status && (
                              <p className="m-0">
                                Status:{' '}
                                <strong className="font-weight-bold">
                                  {register.status ?? '---'}
                                </strong>
                              </p>
                            )}
                            {register?.tableName && (
                              <p className="m-0">
                                Tabela:{' '}
                                <strong className="font-weight-bold">
                                  {register.tableCode} - {register.tableName}
                                </strong>
                              </p>
                            )}

                            <p className="m-0">
                              Situação:{' '}
                              <strong className="font-weight-bold">
                                {register.situation ?? '---'}
                              </strong>
                            </p>
                            <p className="m-0">
                              Atividade:{' '}
                              <strong className="font-weight-bold">
                                {register.activity ?? '---'}
                              </strong>
                            </p>
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <UncontrolledDropdown>
                              <DropdownToggle caret color="secondary">
                                Opções
                              </DropdownToggle>
                              <DropdownMenu dark>
                                <DropdownItem
                                  href={`/admin/proposal-details/${
                                    register.proposalId ?? 0
                                  }`}
                                  target="_blank"
                                >
                                  <i className="fas fa-edit" /> Detalhes do
                                  Contrato
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setTagDataModal({
                                      isOpen: true,
                                      clientId: register.clientId,
                                    });
                                  }}
                                >
                                  <i className="fas fa-tags" /> Etiquetas
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </TableData>
                          <TableData className="d-xl-none">
                            <CaretRight
                              active={tableDetails === key}
                              onClick={() => handleTableDetails(key)}
                            />
                          </TableData>
                        </TableRow>
                        {tableDetails === key && (
                          <TableRow className="d-xl-none">
                            <TableData colSpan={10}>
                              <ContentWrapper className="d-xl-none">
                                <MobileTitle>Contrato</MobileTitle>
                                <p className="m-0">
                                  Banco:{' '}
                                  <strong className="font-weight-bold">
                                    {register.febrabanId} - {register.nameBank}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  Proposta:{' '}
                                  <Badge color="warning">
                                    <strong className="font-weight-bold">
                                      {register.numberProposal}
                                    </strong>
                                  </Badge>
                                </p>
                                <p className="m-0">
                                  Vlr. Total:{' '}
                                  <strong className="font-weight-bold">
                                    R$ {moneyMask(register.valueFinally)}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  Vlr. Liberado:{' '}
                                  <strong className="font-weight-bold">
                                    R$ {moneyMask(register.valueLiberty)}
                                  </strong>
                                </p>
                              </ContentWrapper>
                              <ContentWrapper>
                                <MobileTitle>Status</MobileTitle>
                                <p className="m-0">
                                  Situação:{' '}
                                  <strong className="font-weight-bold">
                                    {register.situation ?? '---'}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  Atividade:{' '}
                                  <strong className="font-weight-bold">
                                    {register.activity ?? '---'}
                                  </strong>
                                </p>
                              </ContentWrapper>
                              <ContentWrapper>
                                <MobileTitle>Opções</MobileTitle>
                                <UncontrolledDropdown>
                                  <DropdownToggle caret color="secondary">
                                    Opções
                                  </DropdownToggle>
                                  <DropdownMenu dark>
                                    <DropdownItem
                                      href={`/admin/proposal-details/${
                                        register.proposalId ?? 0
                                      }`}
                                      target="_blank"
                                    >
                                      <i className="fas fa-edit" /> Detalhes do
                                      Contrato
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setTagDataModal({
                                          isOpen: true,
                                          clientId: register.clientId,
                                        });
                                      }}
                                    >
                                      <i className="fas fa-tags" /> Etiquetas
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </ContentWrapper>
                            </TableData>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            )}

            <CardProposalFgtsComponent
              toggleShowList={toggleShowList}
              listData={data.data}
              setTagDataModal={setTagDataModal}
              handleList={handleSubmit}
              page={page}
              pages={pages}
              setPage={setPage}
            />
          </>
        ) : (
          <>
            <Alert color="secondary" className="text-center">
              Não possui simulações no momento
            </Alert>
          </>
        )}

        <ModalTagComponent
          tagDataModal={tagDataModal}
          setTagDataModal={setTagDataModal}
          serviceId={2}
        />
      </Container>
    </>
  );
};

export default ProposalReportFgts;
