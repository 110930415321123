import { useState } from 'react';

import Header from 'components/Headers/Header';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import { moneyMask, maskCPF } from 'utils/mask';
import BankDetailsComponent from 'views/Proposal/components/BankDetailsComponent';
import CardAddressComponent from 'views/Proposal/components/CardAddressComponent';
import CardClientComponent from 'views/Proposal/components/CardClientComponent';
import Swal from 'sweetalert2';
import {
  ShowClientProposalService,
  IndexClientProposalService,
} from 'services/Register/ClientService';

const ClientLifeView = () => {
  const [cpf, setCpf] = useState('');
  const [clientData, setClientData] = useState({ name: null });
  const [proposalList, setProposalList] = useState([]);

  const handleGetClientProposal = async () => {
    setClientData({});
    setProposalList({});

    Swal.fire({
      title: 'Buscando',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await ShowClientProposalService(cpf);
      if (data?.resultSet?.name) {
        setClientData(data?.resultSet);

        if (data.resultSet.clientId > 0)
          handleListProposalClient(data.resultSet.clientId);
      } else {
        setClientData({});
        setProposalList({});
      }

      Swal.close();
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Ocorreu um erro ao buscar os dados.',
      });
    }
  };

  const handleListProposalClient = async (clientId) => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await IndexClientProposalService(clientId);

      if (data?.resultSet.length > 0) {
        setProposalList(data.resultSet);
      } else {
        setProposalList([]);
      }

      Swal.close();
    } catch (err) {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  return (
    <>
      <Header titlePage="Vida do Cliente" />
      <Container className="mt--9" fluid>
        <Card className="mt-3">
          <CardHeader>
            <h2>
              <i className="fa fa-user" /> Buscar Cliente
            </h2>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label>Digite seu CPF: </Label>
              <InputGroup>
                <InputGroupAddon
                  addonType="prepend"
                  onClick={handleGetClientProposal}
                  style={{ cursor: 'pointer' }}
                >
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="000.000.000-00"
                  type="text"
                  value={cpf}
                  onChange={(ev) => setCpf(maskCPF(ev.target.value))}
                />
              </InputGroup>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button onClick={handleGetClientProposal} color="success">
              Buscar
            </Button>
          </CardFooter>
        </Card>
        {clientData?.name ? (
          <div>
            <div>
              <Alert color="warning" className="mt-3">
                {clientData.origin === 'PROPOSAL' &&
                  `Cliente identificado a partir de uma PROPOSTA.`}
                {clientData.origin === 'DIGITATION' &&
                  'Cliente identificado a partir de uma DIGITAÇÃO, menu Esteira de Propostas.'}
                {clientData.origin === 'COLD' &&
                  'Cliente identificado a partir de DADOS FRIOS.'}
              </Alert>
              <CardClientComponent proposal={clientData} />
              <CardAddressComponent proposal={clientData} />
              <BankDetailsComponent proposal={clientData} />
            </div>
          </div>
        ) : (
          <Alert color="secondary" className="text-center">
            Não possui dados no momento
          </Alert>
        )}

        {proposalList.length > 0 && (
          <Card className="shadow mt-4 w-100" style={{ overflow: 'auto' }}>
            <CardHeader>
              <h3 className="m-0">Últimas propostas do cliente</h3>
            </CardHeader>
            <table className="table table-striped table-bordered">
              <thead>
                <tr className="bg-primary text-white">
                  <th>DATA</th>
                  <th>PROPOSTA</th>
                  <th>FINANCEIRA</th>
                  <th>STATUS</th>
                  <th>VALOR</th>
                </tr>
              </thead>
              <tbody>
                {proposalList.map((proposalClient, keyProposalClient) => (
                  <tr key={keyProposalClient}>
                    <td>{proposalClient.createdAt}</td>
                    <td>
                      <Badge color="warning" className="font-12">
                        {proposalClient.numberProposal}
                      </Badge>
                    </td>
                    <td>
                      {proposalClient.febrabanId} - {proposalClient.bankName}
                    </td>
                    <td>{proposalClient.status}</td>
                    <td>R$ {moneyMask(proposalClient.valueLiberty)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ClientLifeView;
