import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Tooltip,
} from 'reactstrap';
import PaginationSelector from 'views/Dashboard/component/PaginationSelector';
import { moneyMask, maskCPF, phoneMask } from 'utils/mask';
import { WhatsAppLink } from '../../Dashboard/styles';
import { BankSelectData } from 'views/AccessBank/service/bankSelectData';

import '../../Dashboard/style/style.scss';

const CardProposalFgtsComponent = ({
  toggleShowList = 1,
  listData = [],
  pages,
  setPage = () => {},
  page,
  handleList = () => {},
  setTagDataModal,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState({});

  const handleSelectColorStatus = (status) => {
    switch (status) {
      case 'SIMULAÇÃO NÃO FINALIZADA':
        return 'warning';
      case 'DIGITAÇÃO REALIZADA':
        return 'success';
      default:
        return 'primary';
    }
  };

  const toggleTooltip = (id) => {
    setTooltipOpen((prevTooltipOpen) => ({
      ...prevTooltipOpen,
      [id]: !prevTooltipOpen[id],
    }));
  };

  if (toggleShowList > 0) {
    return null;
  }

  return (
    <>
      <Card className="mt-1 py-4 bg-transparent border-0">
        {listData.length > 0 && (
          <>
            <PaginationSelector
              pages={pages}
              list={handleList}
              setPage={setPage}
              page={page}
            />
            <Row className="mt-4">
              {listData.map((register, index) => {
                const uniqueIndex = `${index}-${register.proposalId}`;
                const statusBankTooltipId = `statusBankTooltipId-${register.statusBank}-${uniqueIndex}`;
                const febrabanTooltipId = `febrabanTooltipId-${uniqueIndex}`;
                const nameTooltipId = `nameTooltipId-${uniqueIndex}`;
                const proposalTooltipId = `proposal-${uniqueIndex}`;
                const codeIndicationTooltipId = `codeIndicationTooltipId-${uniqueIndex}`;
                const localeSimulationTooltip = `localeSimulationTooltip-${uniqueIndex}`;
                const statusTooltip = `statusTooltip-${uniqueIndex}`;
                const nameIndicationTooltip = `nameIndication-${uniqueIndex}`;

                return (
                  <Col
                    md="4"
                    className="d-flex align-items-stretch"
                    key={`card-${register.proposalId}`}
                  >
                    <Card className="shadow mb-4 w-100">
                      <CardBody>
                        {register?.messageReturnService && (
                          <Alert color="warning">
                            {register?.messageReturnService}
                          </Alert>
                        )}
                        <div className="mb-2">
                          <Badge
                            id={localeSimulationTooltip}
                            color="secondary"
                            className="font-12 w-100 overflow-none"
                            onMouseEnter={() =>
                              toggleTooltip(localeSimulationTooltip)
                            }
                            onMouseLeave={() =>
                              toggleTooltip(localeSimulationTooltip)
                            }
                          >
                            {register.localeSimulation}
                          </Badge>
                          {localeSimulationTooltip && (
                            <Tooltip
                              placement="top"
                              isOpen={
                                tooltipOpen[localeSimulationTooltip] || false
                              }
                              target={localeSimulationTooltip}
                              toggle={() =>
                                toggleTooltip(localeSimulationTooltip)
                              }
                            >
                              {register.localeSimulation}
                            </Tooltip>
                          )}
                          <Badge
                            id={statusTooltip}
                            className="font-12 mt-2 w-100 overflow-none"
                            color={handleSelectColorStatus(register.status)}
                            onMouseEnter={() => toggleTooltip(statusTooltip)}
                            onMouseLeave={() => toggleTooltip(statusTooltip)}
                          >
                            {register.status}
                          </Badge>
                          {statusTooltip && (
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[statusTooltip] || false}
                              target={statusTooltip}
                              toggle={() => toggleTooltip(statusTooltip)}
                            >
                              {register.status}
                            </Tooltip>
                          )}
                          <Badge
                            id={statusBankTooltipId}
                            className="font-12 mt-2 w-100 overflow-none"
                            color="secondary"
                            onMouseEnter={() =>
                              toggleTooltip(statusBankTooltipId)
                            }
                            onMouseLeave={() =>
                              toggleTooltip(statusBankTooltipId)
                            }
                          >
                            {register.statusBank}
                          </Badge>
                          {statusBankTooltipId && (
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[statusBankTooltipId] || false}
                              target={statusBankTooltipId}
                              toggle={() => toggleTooltip(statusBankTooltipId)}
                            >
                              {register.statusBank}
                            </Tooltip>
                          )}
                        </div>
                        {register.febrabanId !== '999' && (
                          <>
                            {BankSelectData(register.febrabanId)?.img && (
                              <img
                                src={BankSelectData(register.febrabanId).img}
                                alt={register.nameBank}
                                width="40%"
                              />
                            )}
                            <p
                              className="m-0 overflow-none"
                              id={febrabanTooltipId}
                              onMouseEnter={() =>
                                toggleTooltip(febrabanTooltipId)
                              }
                              onMouseLeave={() =>
                                toggleTooltip(febrabanTooltipId)
                              }
                            >
                              <strong>{register.nameBank}</strong>
                            </p>
                            {febrabanTooltipId && (
                              <Tooltip
                                placement="top"
                                isOpen={tooltipOpen[febrabanTooltipId] || false}
                                target={febrabanTooltipId}
                                toggle={() => toggleTooltip(febrabanTooltipId)}
                              >
                                {register.nameBank}
                              </Tooltip>
                            )}
                          </>
                        )}
                        <h3
                          className="mt-3 mb-0 overflow-none"
                          color="secondary"
                          id={nameTooltipId}
                          onMouseEnter={() => toggleTooltip(nameTooltipId)}
                          onMouseLeave={() => toggleTooltip(nameTooltipId)}
                        >
                          {register.name}
                        </h3>
                        {nameTooltipId && (
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[nameTooltipId] || false}
                            target={nameTooltipId}
                            toggle={() => toggleTooltip(nameTooltipId)}
                          >
                            {register.name}
                          </Tooltip>
                        )}
                        {register.numberProposal && (
                          <>
                            <p
                              className="m-0 overflow-none"
                              id={proposalTooltipId}
                              onMouseEnter={() =>
                                toggleTooltip(proposalTooltipId)
                              }
                              onMouseLeave={() =>
                                toggleTooltip(proposalTooltipId)
                              }
                            >
                              Proposta:{' '}
                              <strong>{register.numberProposal}</strong>
                            </p>
                            {proposalTooltipId && (
                              <Tooltip
                                placement="top"
                                isOpen={tooltipOpen[proposalTooltipId] || false}
                                target={proposalTooltipId}
                                toggle={() => toggleTooltip(proposalTooltipId)}
                              >
                                {register.numberProposal}
                              </Tooltip>
                            )}
                          </>
                        )}
                        <p className="m-0">
                          Vlr Liberado:{' '}
                          <strong>
                            R$ {moneyMask(register?.valueLiberty ?? 0.0)}
                          </strong>
                        </p>
                        <p className="m-0">
                          Vlr Total:{' '}
                          <strong>
                            R$ {moneyMask(register?.valueFinally ?? 0.0)}
                          </strong>
                        </p>
                        <p className="m-0">
                          CPF: <strong>{maskCPF(register.cpf)}</strong>
                        </p>
                        <p className="m-0">
                          Data Nascimento:{' '}
                          <strong>
                            {new Date(register.birthDate).toLocaleDateString(
                              'pt-BR'
                            )}
                          </strong>
                        </p>
                        <p className="m-0">
                          WhatsApp:{' '}
                          <strong>
                            {phoneMask(register.whatsappNumber)}{' '}
                            <WhatsAppLink
                              className="btn pl-1 pr-1 btn-success"
                              href={register.urlWhatsapp}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fab fa-whatsapp" />
                            </WhatsAppLink>
                          </strong>
                        </p>
                        <p className="m-0">
                          Data simulação: <strong>{register.createdAt}</strong>
                        </p>
                        {register?.codeIndication && (
                          <Alert color="success" className="mt-2 text-white">
                            <h4 className="m-0">Indicação / Vendedor</h4>
                            <p
                              className="m-0 font-12 overflow-none"
                              id={codeIndicationTooltipId}
                              onMouseEnter={() =>
                                toggleTooltip(codeIndicationTooltipId)
                              }
                              onMouseLeave={() =>
                                toggleTooltip(codeIndicationTooltipId)
                              }
                            >
                              Código: <strong>{register.codeIndication}</strong>
                            </p>
                            {codeIndicationTooltipId && (
                              <Tooltip
                                placement="top"
                                isOpen={
                                  tooltipOpen[codeIndicationTooltipId] || false
                                }
                                target={codeIndicationTooltipId}
                                toggle={() =>
                                  toggleTooltip(codeIndicationTooltipId)
                                }
                              >
                                {register.codeIndication}
                              </Tooltip>
                            )}
                            <p
                              className="m-0 font-12 overflow-none"
                              id={nameIndicationTooltip}
                              onMouseEnter={() =>
                                toggleTooltip(nameIndicationTooltip)
                              }
                              onMouseLeave={() =>
                                toggleTooltip(nameIndicationTooltip)
                              }
                            >
                              Nome:{' '}
                              <strong className="font-weight-bold">
                                {register.nameIndication}
                              </strong>
                            </p>
                            <Tooltip
                              placement="top"
                              isOpen={
                                tooltipOpen[nameIndicationTooltip] || false
                              }
                              target={nameIndicationTooltip}
                              toggle={() =>
                                toggleTooltip(nameIndicationTooltip)
                              }
                            >
                              {register.nameIndication}
                            </Tooltip>
                          </Alert>
                        )}
                        <div className="mt-3">
                          <a
                            className="btn btn-none mr-0"
                            id={`detailsTooltip-${register.id}-${index}`}
                            href={`/admin/proposal-details/${
                              register.proposalId ?? 0
                            }`}
                            target="_blank"
                          >
                            <i className="fas fa-tag" />
                            <Tooltip
                              placement="top"
                              isOpen={
                                tooltipOpen[
                                  `detailsTooltip-${register.id}-${index}`
                                ] || false
                              }
                              target={`detailsTooltip-${register.id}-${index}`}
                              toggle={() =>
                                toggleTooltip(
                                  `detailsTooltip-${register.id}-${index}`
                                )
                              }
                            >
                              Detalhes do Contrato
                            </Tooltip>
                          </a>
                          <Button
                            color="none"
                            className="mr-0"
                            id={`tagsTooltip-${register.id}-${index}`}
                            onClick={() => {
                              setTagDataModal({
                                isOpen: true,
                                clientId: register.clientId,
                              });
                            }}
                          >
                            <i className="fas fa-tags" />
                            <Tooltip
                              placement="top"
                              isOpen={
                                tooltipOpen[
                                  `tagsTooltip-${register.id}-${index}`
                                ] || false
                              }
                              target={`tagsTooltip-${register.id}-${index}`}
                              toggle={() =>
                                toggleTooltip(
                                  `tagsTooltip-${register.id}-${index}`
                                )
                              }
                            >
                              Etiquetas
                            </Tooltip>
                          </Button>
                        </div>
                        {register?.tags?.length > 0 && (
                          <div className="mt-3">
                            {register.tags.map((tag, tagKey) => (
                              <Badge
                                key={tagKey}
                                className={`${tag.color} mr-1 mb-1 font-12 shadow`}
                              >
                                {tag.title}
                              </Badge>
                            ))}
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <PaginationSelector
              pages={pages}
              list={handleList}
              setPage={setPage}
              page={page}
            />
          </>
        )}
      </Card>
    </>
  );
};

export default CardProposalFgtsComponent;
